import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export default class MathValidators {
  static minLengthTrimmed(min: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.trim() || '';
      return value.length >= min ? null : { minlength: { requiredLength: min, actualLength: value.length } };
    }
  }

  static atLeastOne = (
    validator: ValidatorFn,
    controlsNames: string[] = null,
  ) => (formGroup: FormGroup): ValidationErrors | null => {
    if (!controlsNames) {
      controlsNames = Object.keys(formGroup.controls);
    }

    const hasAtLeastOne =
      formGroup &&
      formGroup.controls &&
      controlsNames.some((controlName) => !validator(formGroup.controls[controlName]));

    return hasAtLeastOne ? null : { atLeastOne: true };
  }

  static email(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;

      return emailRegex.test(control.value) ? null : { email: true };
    };
  }

  static passwordsMatchValidator(passwordFieldName: string, passwordConfirmFieldName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = control.get(passwordFieldName);
      const passwordConfirm = control.get(passwordConfirmFieldName);

      if (password && passwordConfirm && password.value !== passwordConfirm.value) {
        passwordConfirm.setErrors({ passwordsMismatch: true });
      } else if (passwordConfirm) {
        passwordConfirm.setErrors(null);
      }
      return null;
    };
  }
}
